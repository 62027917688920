<template>
	<div class="outer-wrapper mobile-only">

		<div id="top-nav">
			<div v-if="tripItemID" @click="back()">
				<img src="@/assets/back-icon.png" alt="back" class="back-icon"/>
			</div>
			<router-link v-else :to="{ name: 'Trip', params: { trip_id: this.tripID }}">
				<img src="@/assets/back-icon.png" alt="back" class="back-icon"/>
			</router-link>
		</div>

		<div class="confirmation" :class="paymentStatus">
			<div class="inner-wrapper">
				<div class="heading4">Booking Result</div>

				<Loader :loading="loading || trip.loading" :error="trip.error" @refresh="init"/>

				<div v-if="!loading && !trip.loading && !trip.error">

					<div v-if="paymentStatus === 'processing'">
						<BookingResult class="result" type="payment-processing" :message="message" />
						<div class="information">
							<div class="heading7">We are processing your payment</div>
							<div class="text">
								Please check back later to see the booking result.
							</div>
							<div class="action">
								<button class="button" @click="$router.go()">Refresh</button>
							</div>
							<div class="sub">
								If problems persist, you can contact us at <span v-html="supportEmailLink()"></span> using the booking reference number {{ tripID }}<span v-if="tripItemID">_{{ tripItemID }}</span>.
							</div>
						</div>
					</div>

					<div v-if="paymentStatus === 'fail' || paymentStatus == 'other'">
						<BookingResult class="result" type="payment-failed" :message="message" />
						<div class="information">
							<div class="heading7">We are sorry!</div>
							<div class="text">
								Please try a different payment method or contact your bank.
							</div>
							<div class="action">
								<button class="button" @click="back()">Try again</button>
							</div>
							<div class="sub">
								If problems persist, you can contact us at <span v-html="supportEmailLink()"></span> using the booking reference number {{ tripID }}<span v-if="tripItemID">_{{ tripItemID }}</span>.
							</div>
						</div>
					</div>

					<div class="item-results" v-if="paymentStatus === 'success'">
						<!-- TODO: Loop through the booked items and show the booking results -->
						<div v-for="bookedItem in tripItems" :key="bookedItem.id">
							<BookingResult 
								class="result" 
								:type="bookedItem.bookingStatus.code == 'booked' ? 'booking-success' : 'booking-issue'"
								:tripItem="bookedItem"
							/>
							<div class="booking-codes" v-if="bookedItem.bookingStatus.code == 'booked'">
								<div class="bookingID" v-if="bookedItem.type == 'flight'">Booking PNR: <span>{{ bookedItem.bookingStatus.PNR }}</span></div>
								<div class="bookingID" v-if="bookedItem.type == 'tour'">Booking ID: <span>{{ bookedItem.bookingStatus.gapiBookingID }}</span></div>
							</div>
							<div class="information" v-else>
								<div class="heading7">We are sorry!</div>
								<div class="text">
									Something may have gone wrong when booking this item. Someone from our team is looking into it, and will be in touch. You will receive an email.
								</div>
								<div class="text">
									Reference ID: {{ tripID}}_{{ bookedItem.id }}
								</div>
								<div class="sub">
									If problems persist, you can contact us at <span v-html="supportEmailLink()"></span> using the booking reference number {{ tripID }}<span v-if="tripItemID">_{{ tripItemID }}</span>.
								</div>
							</div>
							<div class="booking-details" :class="{ selected: bookedItem.show }">
								<div class="expand-button" @click="bookedItem.show = !bookedItem.show">
									<span class="text">Booking details </span>
									<img class="arrow" src="@/assets/trip/arrow-up.png" :class="{ selected: bookedItem.show }"/>
								</div>
								<div class="collapsible-section">
									<TripItem 
										:id="bookedItem.id"
										:tripID="trip.tripID"
										:type="bookedItem.type"
										:name="bookedItem.name"
										:price="bookedItem.price"
										:date="bookedItem.date"
										:travellers="bookedItem.travellers"
										:bookingStatus="bookedItem.bookingStatus"
										:alert="bookedItem.alerts.all.length > 0 ? bookedItem.alerts.all[0] : false"
										:data="bookedItem.data"
										:showContinueButton="false"
										class="item"
									/>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>

			<div v-if="paymentStatus === 'success' && !loading && !trip.loading && !trip.error">

				<TripItemsSummary 
					class="summary"
					:tripID="trip.tripID"
					:groupedTripItems="{ 
						'notCategorized': {
							items: tripItems,
							totalPrice: tripItems.reduce((a, b) => Math.round((parseFloat(a) + parseFloat(b.price)) * 100) / 100, 0),
							heading: 'Booking Summary'
						},
					}"
					:showBookButton="true"
					@book="goToPayment()"
				/>

				<router-link :to="{ name: 'Trip', params: { trip_id: this.tripID }}" v-if="paymentStatus === 'success' || paymentStatus === 'fail' || paymentStatus === 'other'">
					<button class="button">View Trip</button>
				</router-link>
				<button v-if="paymentStatus === 'processing'" class="button" @click="init()">Refresh</button>
				<router-link :to="{ name: 'Payment', params: { trip_id: this.tripID, trip_item_id: this.tripItemID }}" v-if="paymentStatus === 'fail'">
					<button class="button">Try Again</button>
				</router-link>

			</div>
		</div>
	</div>
</template>

<script>
import Loader from '@/components/Loader.vue';
import { mapState, mapActions } from 'vuex'
import TripItem from '@/components/trips/TripItem.vue';
import ResultIcon from '@/components/ResultIcon.vue';
import BookingResult from '@/components/trips/BookingResult.vue';
import router from '@/router';
import { tripService } from '@/services/tripService.js';
import constants from '@/helpers/constants.js';
import TripItemsSummary from '@/components/trips/TripItemsSummary.vue';
import track from "@/helpers/track.js";

export default {
	data() {
		return {
			tripID: this.$route.params.trip_id,
			tripItemID: this.$route.params.trip_item_id ? this.$route.params.trip_item_id : null,
			
			tripItems: [], // The trip items that were booked in this booking

			paymentStatus: "",
			message: "",

			loading: true,

			// Stripe objects
			stripe: null,
			elements: null,
		}
	},
	components: {
		Loader,
		TripItem,
		ResultIcon,
		BookingResult,
		TripItemsSummary,
	},
	methods: {
		supportEmailLink: (linkText) => constants.supportEmailLink(linkText),
		...mapActions({
			initTrip: "trip/initTrip",
			alertSuccess: "alert/success",
			alertError: "alert/error",
			alertInfo: "alert/info",
			alertWarning: "alert/warning",
		}),
		back() {
			if(this.tripItemID) {
				if(this.trip.tripItems[this.tripItemID].type == "tour") {
					router.push({
						name: "Saved Tour Details",
						params: {
							trip_id: this.tripID,
							trip_item_id: this.tripItemID
						}
					})
				} else if(this.trip.tripItems[this.tripItemID].type == "flight") {
					router.push({
						name: "Saved Flights Details",
						params: {
							trip_id: this.tripID,
							trip_item_id: this.tripItemID
						}
					})
				}
			} else {
				router.push({
					name: "Trip",
					params: {
						trip_id: this.tripID
					}
				})
			}
			
		},
		goToPayment() {
			router.push({
				name: "Payment",
				params: {
					trip_id: this.tripID,
					trip_item_id: this.tripItemID
				}
			})
		},
		async init() {
			this.loading = true;

			// Initialize Stripe.js using your publishable key
			this.stripe = Stripe(process.env.VUE_APP_STRIPE_KEY); // TODO Make this config based.

			// Retrieve the "payment_intent_client_secret" query parameter appended to
			// your return_url by Stripe.js
			const clientSecret = this.$route.query['payment_intent_client_secret'];
			let paymentIntent = null;

			if(clientSecret) { // If the client secret is not present, then skip this step.
				// Retrieve the PaymentIntent
				paymentIntent = await this.stripe.retrievePaymentIntent(clientSecret);
				paymentIntent = paymentIntent.paymentIntent;

				// Inspect the PaymentIntent `status` to indicate the status of the payment
				// to your customer.
				//
				// Some payment methods will [immediately succeed or fail][0] upon
				// confirmation, while others will first enter a `processing` state.
				//
				// [0]: https://stripe.com/docs/payments/payment-methods#payment-notification
				switch (paymentIntent.status) {
					case 'succeeded':
						this.paymentStatus = "success";
						this.message = 'Success! Payment received.';
						break;

					case 'processing':
						this.paymentStatus = "processing";
						this.message = "Payment processing. Please check back later.";
						break;

					case 'requires_payment_method':
						this.paymentStatus = "fail";
						this.message = 'Payment failed. Please try another payment method.';
						// Redirect your user back to your payment page to attempt collecting
						// payment again
						break;

					default:
						this.paymentStatus = "other";
						this.message = 'Something went wrong.';
						break;
				}
			} else {
				this.paymentStatus = "other";
				this.message = "Couldn't fetch payment status."
			}

			
			await this.initTrip({ tripID: this.tripID, force: true}); // Force a refresh of the trip data, in case the refresh button was used to trigger this

			// Use only relevant trip items for this transaction
			if(this.tripItemID) {
				this.tripItems.push({...this.trip.tripItems[this.tripItemID], show: false}); // Default to not showing the trip item
			} else {
				let tripItemsResponse = await tripService.fetchPaymentItems(this.$route.query['payment_intent'])
			
				for(let tripItemID of tripItemsResponse.data.data.trip_items) {
					this.tripItems.push({...this.trip.tripItems[tripItemID], show: false}); // Default to not showing the trip item
				}
				// TODO: How do we get the trip items that were booked in THIS INSTANCE, not all the trip items on the booking?
				// Probably we will need some sort of booking ID to be passed from the backend, with a list of the items that were attempted to be booked.
				// Or maybe we can just use the payment intent ID to get the list of items that were booked?
			}

			// Loop through each TripItem and determine if payment is still "processing"
			for(let tripItemID in this.tripItems) {
				let tripItem = this.tripItems[tripItemID];

				// If the payment status is successful, but any trip item is not booked, then mark as payment processing. We can assume here that the backend is just a bit slower than the payment result.
				// If all items are booked or unknown, then we can show the individual item results.
				if(tripItem.bookingStatus.code == "not_booked" && tripItem.bookingStatus.totalPaid == 0) {
					this.paymentStatus = "processing";
					this.message = "Your payment is still processing";
				}
			}
	
			// if(this.tripItemID) {
			// 	if(this.tripItems[this.tripItemID].bookingStatus.code === "booked") { // Regardless of payment status, if the trip is booked, then mark as success. Handles scenarios where the payment query param is not present. Assumes backend is greater source of truth than payment status.
			// 		this.paymentStatus = "success";
			// 		this.message = "Booking successful.";
			// 	} else if(this.paymentStatus == "success" && this.trip.tripItems[this.tripItemID].bookingStatus.code === "not_booked") { // The payment confirmation may be quicker than the backend could update the trip. Mark as processing anyway.
			// 		this.paymentStatus = "processing";
			// 		this.message = "Booking processing. Please check back later.";
			// 	} else if(this.paymentStatus == "success" && this.trip.tripItems[this.tripItemID].bookingStatus.code === "unknown") { // Something went wrong on the backend when marking as booked.
			// 		this.paymentStatus = "other";
			// 		this.message = "Something went wrong. Please contact us.";
			// 	}
			// }

			// // TESTING STYLING
			// this.paymentStatus = "processing";
			// this.message = "Your payment is still processing";
			if(this.paymentStatus == 'success') { // Track only if successful. This will always track booking even with booking-issue / unknown status. We have already taken payment for these, so we track them and assume issues will be sorted.
				try {
					track.ecommerce("purchase", { // if the user loads this page twice, it will track the second time as a duplicate. For now this is okay - we will check if this is a problem later.
						transaction_id: paymentIntent.id,
						currency: "GBP",
						value: this.tripItems.reduce((a, b) => Math.round((parseFloat(a) + parseFloat(b.price)) * 100) / 100, 0),
					}, [this.trip.tripItems[this.tripItemID]]);
				} catch(e) {
					console.error(e);
					this.$rollbar.error("Tracking: " + e);
				}
			}

			this.loading = false;
		}
	},
	computed: {
		...mapState({
			trip: state => state.trip,
		}),
	},
	async mounted () {		
		let externalScript = document.createElement('script')
		externalScript.setAttribute('src', 'https://js.stripe.com/v3/')
		document.head.appendChild(externalScript);

		// Wait 3 seconds
		await wait(3000); // Wait 3 seconds before showing the payment status. This is to allow the payment to be processed and the backend to be updated. May not always work but should be better. If it doesn't work, user gets a 'processing' message and refresh button.
		await this.init();
	},
}

async function wait(ms) {
	return new Promise(resolve => {
		setTimeout(resolve, ms);
	});
}
</script>

<style scoped>
	/* .inner-wrapper {
		padding: 80px 10px 0;
	} */

	.heading4 {
		margin-bottom: 25px;
	}
	.heading {
		font-size: 20px;
		font-weight: 700;
		color: #118AB2;
		margin: 30px 0px 16px 0px;
	}
	.confirmation button {
		font-size: 16px;
		font-weight: 700;
		display: block;
		margin: 0 auto;
		width: 256px;
		height: 50px;
		text-decoration: none;

		margin-top: 10px;
	}
	.confirmation a {
		text-decoration: none;
	}

	.summary {
		margin-top: 20px;
	}

	.item-results {
		margin-bottom: 20px;
	}
	.result {
		margin-bottom: 20px;
	}

	.information .heading7 {
		margin-bottom: 0;
		font-weight: 700;
	}
	.information .text {
		font-size: 12px;
	}
	.information .button {
		margin-top: 20px;
	}
	.information .sub {
		font-weight: 400;
		font-size: 12px;
		line-height: 15px;
		text-align: center;
		margin: 0 auto;
		margin-top: 20px;
		max-width: 300px;
	}
	.booking-codes {
		font-weight: 600;
		font-size: 20px;
		line-height: 24px;
		color: #000000;
		text-align: center;
	}
	.booking-codes span {
		color: #06D6A0;
	}

	.booking-details {
		margin-top: 20px;
		margin-bottom: 20px;
	}
	.booking-details .expand-button {
		text-align: center;
		vertical-align: middle;
		cursor: pointer;
	}
	.booking-details .text {
		color: #000000;
		font-weight: 600;
		font-size: 12px;
		line-height: 15px;
		vertical-align: middle;
	}
	.booking-details .arrow {
		width: 24px;
		display: inline-block;
		transform: rotate(180deg);
		vertical-align: middle;
	}
	.booking-details .arrow.selected {
		transform: rotate(0deg);
	}
	.booking-details .collapsible-section {
		display: none;
	}
	.booking-details.selected .collapsible-section {
		display: block;
	}
</style>